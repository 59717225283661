/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require('babel-polyfill')

import React, { Fragment } from 'react'
import { LanguageContext } from './src/context'
import Kokuapp from './src/components/Kokuapp'
import '@fortawesome/fontawesome-svg-core/styles.css'

export const wrapPageElement = ({ element, props }) => {
  return (
    <LanguageContext.Provider
      value={{
        currentLang: props.pageContext.language,
        otherPost: props.pageContext.otherPost
      }}
    >
      {element}
    </LanguageContext.Provider>
  )
}

export const wrapRootElement = ({ element, ...props }) => {
  return (
    <Fragment>
      {element}

      { process.env.NODE_ENV !== 'production' &&
        <Kokuapp dynamic />
      }
    </Fragment>
  )
}
