import Url from 'url-parse'
import { hyphenate } from './hypher'

// takes in wordpress menu link object
export const getLinkUrl = linkObject => {
  if (linkObject.object === 'custom') {
    // custom links should not be modified
    return linkObject.url
  } else {
    // to user internal navigation omit the hostname
    return new Url(linkObject.url).pathname
  }
}

export { hyphenate }

export const formsEndpoint = process.env.GATSBY_FORMS_ENDPOINT || 'https://forms-test.ljosleidarinn.is'
