import React, { Component, Fragment } from 'react'
import { formsEndpoint } from '../../utils'
import { TRANSLATIONS } from '../../misc/translations'

const cookieData = {
  privacy: '',
  required: {
    required: true,
    links: ['']
  },
  marketing: {
    required: false,
    links: ['https://www.ljosleidarinn.is/js/gdprMarketing.js']
  },
  data: {
    required: false,
    links: [ 'https://www.ljosleidarinn.is/js/gdprData.js' ]
  },
}
/**
 * component runs in two modes: static and dynamic
 */
class Kokuapp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      once: false,
      load: false
    }
  }

  shouldComponentUpdate () {
    return !this.state.once
  }

  componentDidMount () {
    setTimeout(() => {
      if (this.props.dynamic && !this.state.once) {
        this.appendScriptsToHead()
      }
      this.setState({ load: true, once: true })
    }, 1500)
  }

  getLanguage () {
    return this.props.language || 'is'
  }

  urlFor (filename) {
    return `${formsEndpoint}/dist/${filename}?version=${Date.now()}`
  }

  getScripts () {
    const manifestExtScr = this.urlFor('js/manifest.js')
    const vendorExtScr = this.urlFor('js/vendor.js')
    const formExtScr = this.urlFor('js/kokuapp_onload.js')

    return [
      { url: manifestExtScr },
      { url: vendorExtScr },
      { url: formExtScr, dataUrl: formsEndpoint, dataLang: this.getLanguage() }
    ]
  }

  appendScriptsToHead () {
    const head = document.querySelector('head')

    this.getScripts()
      .map(this.createScript)
      .forEach(script => {
        head.appendChild(script)
      })

    this.getStyles()
      .map(this.createLink)
      .forEach(link => {
        head.appendChild(link)
      })
  }

  getStyles () {
    return [
      this.urlFor('css/kokuapp.css'),
      this.urlFor('css/komduividskipti.css')
    ]
  }

  createLink (href) {
    let link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = href
    link.defer = true

    return link
  }

  createScript ({ url, dataUrl, dataLang }) {
    let lf = document.createElement('script')
    lf.type = 'text/javascript'
    lf.defer = true
    lf.src = url

    if (dataUrl) {
      lf.dataset.url = dataUrl
    }

    if (dataLang) {
      lf.dataset.lang = dataLang || 'is'
    }

    lf.dataset.cookieversion = '1'
    lf.dataset.cookie = JSON.stringify(cookieData)

    return lf
  }

  renderScripts () {
    return this.getScripts()
      .map(({ url, dataUrl, dataLang }) => (
        <CookieScript
          url={url}
          dataUrl={dataUrl}
          dataLang={dataLang} />
      ))
  }
  render () {
    const { load } = this.state
    return (
      <Fragment>
        {!this.props.dynamic && load && this.renderScripts()}

        <div id='form-client-cookies' className='mh-100vh' />
      </Fragment>
    )
  }
}

const CookieScript = ({ url, dataUrl, dataLang }) => {
  const cookieLink = dataLang ? TRANSLATIONS[dataLang].COOKIE_BANNER.PAGE_LINK : TRANSLATIONS['is'].COOKIE_BANNER.PAGE_LINK

  return (
    <script
      key={url}
      type='text/javascript'
      src={url}
      defer
      data-url={dataUrl}
      data-lang={dataLang || 'is'}
      data-cookieversion='1'
      data-cookie={JSON.stringify(cookieData)}
      data-cookie-link={cookieLink}
    />
  )
}

export default Kokuapp
