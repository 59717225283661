// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-global-redirect-global-redirect-js": () => import("./../../../src/page-templates/GlobalRedirect/GlobalRedirect.js" /* webpackChunkName: "component---src-page-templates-global-redirect-global-redirect-js" */),
  "component---src-page-templates-lagnateikningar-lagnateikningar-connected-js": () => import("./../../../src/page-templates/Lagnateikningar/Lagnateikningar.connected.js" /* webpackChunkName: "component---src-page-templates-lagnateikningar-lagnateikningar-connected-js" */),
  "component---src-page-templates-news-item-news-item-connected-js": () => import("./../../../src/page-templates/NewsItem/NewsItem.connected.js" /* webpackChunkName: "component---src-page-templates-news-item-news-item-connected-js" */),
  "component---src-page-templates-news-page-news-page-connected-js": () => import("./../../../src/page-templates/NewsPage/NewsPage.connected.js" /* webpackChunkName: "component---src-page-templates-news-page-news-page-connected-js" */),
  "component---src-page-templates-page-page-connected-js": () => import("./../../../src/page-templates/Page/Page.connected.js" /* webpackChunkName: "component---src-page-templates-page-page-connected-js" */),
  "component---src-page-templates-search-results-search-results-connected-js": () => import("./../../../src/page-templates/SearchResults/SearchResults.connected.js" /* webpackChunkName: "component---src-page-templates-search-results-search-results-connected-js" */),
  "component---src-page-templates-starfsmenn-starfsmenn-connected-js": () => import("./../../../src/page-templates/Starfsmenn/Starfsmenn.connected.js" /* webpackChunkName: "component---src-page-templates-starfsmenn-starfsmenn-connected-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

