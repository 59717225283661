export const TRANSLATIONS = {
  is: {
    NEWS_FOOTNOTE: 'Stafrænt kolefnisspor þessarar fréttar/greinar er áætlað 0,2 til 1,0g CO₂ ígilda á hverja heimsókn.',
    ANNOUNCEMENT: '<p>Vegna uppfærslu á vefnum má búast við truflunum á vefnum milli kl. 00:00-20:00.</p><p>Við biðjumst velvirðingar á óþægindum vegna þessa.</p>',
    TEXT: 'texti',
    allNews: 'Sjá allar fréttir',
    webChat: 'Netspjall',
    connectionHeading: 'Get ég tengst?',
    clear: 'Hreinsa',
    seeMore: 'Sjá meira',
    AT: 'hjá',
    newsParentSlug: 'frettir',
    SEARCH_STAFF: 'Leitaðu að starfsfólki eða -sviði',
    searchPageTitle: 'Leit­arnið­ur­stöður',
    searchPageInputTitle: 'Þú leitaðir að...',
    searchInputPlaceholder: 'Leit á vefnum',
    footerSocialTitle: 'Við erum líka hér',
    searchPage: '/leit',
    ssn: 'Kennitala',
    name: 'Nafn',
    jobDescription: 'Verklýsing (hvað á að gera?)',
    contractor: 'Verktaki / framkvæmdaraðili',
    contractorPhonenumer: 'Símanúmer verktaka / framkvæmdaraðila',
    address_constructionsite: 'Heimilisfang / Verkstaður',
    enterStreetName: 'Sláðu inn götuheiti í nefnifalli og húsnúmer',
    aMoment: 'Augnablik',
    congratsYouCanConnect: 'Til hamingju, þú getur tengst.',
    phoneNumber: 'Símanúmer',
    phone_number: 'Sími',
    email: 'Netfang',
    postcode: 'Póstnúmer',
    requiredFields: '* Verður að fylla út',
    myOderStatus: 'Hvar er pöntunin mín stödd?',
    view: 'Skoða',
    order: 'Pöntun',
    code: 'Kóði',
    from: 'Frá',
    ordersFound: 'pantanir fundust.',
    apartment: 'Íbúð',
    houseCallBooked: 'Heimsókn er bókuð',
    bookHouseCall: 'Bóka heimsókn',
    ssnValidation: 'Sláðu inn alla kennitöluna.',
    enterCode: 'Sláðu núna inn auðkenningarkóðann.',
    codeMissingMsg: 'Það vantar kóðann.',
    success: 'Tókst!',
    orderstatusUnavailable: 'Gat ekki sótt pöntunarstöðu.',
    orderWatchInfoText: 'Pöntunarvakt Ljósleiðarans gerir þér kleift að sjá stöðu pöntunar. Sláðu inn kennitölu til að nálgast stöðuna og þú færð smáskilaboð til staðfestingar.',
    gettingList: 'Sæki lista',
    chooseServiceProvider: 'Veldu fjarskiptafélag',
    sendSuggestion: 'Ég vil tengjast',
    constuctionStartedTitle: 'Framkvæmdir hafnar, skrá áhuga?',
    noLjosleidariText: 'Ljósleiðarinn er því miður ekki kominn til þín.',
    congratsYouCanConnectMsg: 'Ljósleiðarinn er tengdur inn á þitt heimili. Fylltu út formið hér að neðan, veldu þitt fjarskiptafélag og haft verður samband við þig.',
    constuctionStartedMsg: 'Til hamingju, Framkvæmdir eru hafnar eða að hefjast við lagningu Ljósleiðarans. Innan nokkra mánaða getur þú nýtt þér þjónustu um Ljósleiðarann. Tilkynning verður send þegar þjónusta er í boði yfir Ljósleiðarann.',
    addressErrorText: 'Við eigum eftir að tengjast þessu heimilisfangi. Við viljum að öll hafi tækifæri til að tengjast Ljósleiðaranum. Skráðu þig hér fyrir neðan og hjálpaðu okkur að móta næstu áætlanir um tengingar Ljósleiðarans.',
    dreifbyliMsg: 'Skráðu þig ef þú hefur áhuga á Ljósleiðaranum og við látum þig vita þegar þitt heimili verður tengt',
    sendTipSuccessTitle: 'Beiðni þín hefur verið móttekin',
    sendTipSuccessCanConnectMsg: 'Við þökkum áhugann. Við höfum móttekið ábendinguna og við höfum samband þegar þitt heimili getur tengst Ljósleiðaranum.',
    oneVisit: 'Takk fyrir! Þú færð símtal frá þínu fjarskiptafyrirtæki von bráðar.\n\nLjósleiðarinn leggur áherslu á skjóta og örugga þjónustu fyrir þig. Í einni heimsókn eru þráðlaus tæki heimilisins tengd við ljósleiðara þar sem möguleikarnir eru endalausir.',
    unableToSendRequest: 'Ekki náðist að senda beiðni.',
    emptyInputError: 'Þennan reit þarf að fylla út',
    constructionStart: 'Verkið hefst',
    sendIn: 'SENDA INN',
    selectFiles: 'Veldu skrár',
    dropFiles: 'Dragðu skjöl hingað til að hlaða upp',
    attachment: 'Viðhengi',
    REQUIRED: 'Þennan reit þarf að fylla út',
    INVALID_SSN: 'Vinsamlegast sláðu inn gilda kennitölu',
    MIN_AGE_18: 'Umsækjandi þarf að vera orðinn 18 ára eða eldri',
    POSTCODE_FORMAT: 'Póstnúmer verður að vera 3 tölustafir',
    NEXT_PAGE: 'Næsta síða',
    PREV_PAGE: 'Fyrri síða',
    COOKIE_BANNER: {
      PAGE_LINK: '/vafrakokur'
    },
    NEEDS_COOKIES: 'Virkjaðu tölfræðilegar vafrakökur til að sýna myndbandið',
    SHOW_COOKIE_SETTINGS: 'Vefköku stillingar',
  },
  en: {
    NEWS_FOOTNOTE: 'The digital emissions from this story are an estimated 0.2g to 1,0g CO₂ per pageview.',
    ANNOUNCEMENT: '<p>Due to an update on the web, some features will be unavailable between 12am and 8pm today.</p><p>We apologize for any inconvenience.</p>',
    TEXT: 'text',
    allNews: 'See all news',
    webChat: 'Live chat',
    connectionHeading: 'Can I connect?',
    clear: 'Clear',
    seeMore: 'See more',
    newsParentSlug: 'en/news',
    AT: 'at',
    SEARCH_STAFF: 'Search for an employee or section',
    searchPageTitle: 'Search results',
    searchPageInputTitle: 'You searched for...',
    searchInputPlaceholder: 'Search on site',
    footerSocialTitle: 'You can find us here',
    searchPage: '/en/search',
    ssn: 'Social security number',
    name: 'Name',
    postcode: 'postcode',
    contractor: 'Verktaki / framkvæmdaraðili',
    contractorPhonenumer: 'Símanúmer verktaka / framkvæmdaraðila',
    address_constructionsite: 'Heimilisfang / Verkstaður',
    enterStreetName: 'Type in your street name and number.',
    aMoment: 'Just a moment',
    congratsYouCanConnect: 'Congratulations, you can connect.',
    phoneNumber: 'Phone numer',
    phone_number: 'Phone',
    email: 'Email',
    requiredFields: '* Required fields',
    myOderStatus: 'Where is my order?',
    view: 'View',
    code: 'Code',
    order: 'Order',
    from: 'From',
    oneVisit: '',
    ordersFound: 'orders found',
    apartment: 'Appartment',
    houseCallBooked: 'Visit has been booked',
    bookHouseCall: 'Book a visit',
    ssnValidation: 'Please type in your entire identification number (kennitala)',
    enterCode: 'Please type in your verification code',
    codeMissingMsg: 'Verification code missing',
    success: 'Success!',
    orderstatusUnavailable: 'Could not retrieve order status',
    orderWatchInfoText: 'Our order tracker lets you to check the status of your order. Type in your identification number (kennitala) to check the status of your order. You’ll get a verification code via sms.',
    gettingList: 'Retrieving list',
    chooseServiceProvider: 'Please select service provide',
    sendSuggestion: 'Give feedback',
    constuctionStartedTitle: 'Construction has stared, register interest?',
    noLjosleidariText: 'Ljósleiðarinn is not available at this address',
    congratsYouCanConnectMsg: 'Ljósleiðarinn is connected at this address and you can order. Choose a service provider from below to get an offer.',
    constuctionStartedMsg: 'Congratulations, we’ve started or are about to start laying fiber in your street. In a few months you’ll be able to get a fiber connection from Ljósleiðarinn. We’ll let you know once everything is ready.',
    addressErrorText: 'Address not found, either the address is not on our schedule or it might be typed in wrong. If you’re sure you have the correct address then you can register interest in getting a fiber connection below. ',
    dreifbyliMsg: 'Please sign up if you are interested and your home might be connected up sooner.',
    sendTipSuccessTitle: 'We’ve received your message',
    sendTipSuccessCanConnectMsg: 'Thanks for your interest. We’ve received your message and will contact you once your home can connect to Ljósleiðarinn. ',
    unableToSendRequest: 'Error, could not send request. Please try again.',
    emptyInputError: 'This field needs to be filled',
    sendIn: 'SEND',
    constructionStart: 'Construction starts',
    selectFiles: 'Select files',
    dropFiles: 'Drop files here',
    attachment: 'Attachment',
    REQUIRED: 'This field is required',
    INVALID_SSN: 'Please enter a valid identification number',
    MIN_AGE_18: 'Applicant must be at least 18 years old',
    POSTCODE_FORMAT: 'Postal code must be exactly 3 digits',
    NEXT_PAGE: 'Next page',
    PREV_PAGE: 'Previous page',
    COOKIE_BANNER: {
      PAGE_LINK: 'en/web-cookies'
    },
    NEEDS_COOKIES: 'Enable analytics cookies to show the embedded video',
    SHOW_COOKIE_SETTINGS: 'Manage my cookie choices',
  }
}
